.fc .fc-timegrid-slot {
  height: 21px !important;
}

.app-calendar .fc .fc-event .fc-event-main {
  overflow: hidden !important;
}

.fc-timegrid-event-harness {
  max-width: 250px !important;
}

.fc-v-event .fc-event-title {
  font-size: 10px;
}

.fc-toolbar {
  text-transform: capitalize;
}

.fc .fc-timegrid .fc-scrollgrid-section .fc-col-header-cell {
  border: 2px dotted #dae1e7 !important;
}
.fc .fc-toolbar .fc-toolbar-title {
  font-size: 16px;
}

.fc .fc-timegrid-slot {
  font-size: 12px;
}

.fc-daygrid-block-event .fc-event-time,
[dir] .fc-daygrid-block-event .fc-event-title {
  font-size: 10px;
  overflow: auto;
  box-sizing: border-box;
  word-break: break-word;
  white-space: break-spaces;
}

.main-menu .navbar-header {
  height: initial !important;
}

.calendar-view-date-picker input {
  display: none !important;
}

.calendar-view-date-picker .flatpickr-calendar {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.calendar-view-date-picker .flatpickr-weekdays {
  /* width: 200px; */
}
.calendar-view-date-picker span.flatpickr-weekday {
  font-size: 75% !important;
}

div.calendar-view-date-picker .flatpickr-calendar {
  width: inherit !important;
  font-size: 15px;
}

.calendar-view-date-picker .dayContainer {
  width: 100% !important;
  min-width: 100% !important;
  width: 100%;
  max-width: 100% !important;
}

.calendar-view-date-picker .flatpickr-monthDropdown-months {
  width: 100%;
  text-align: center;
  font-size: 13px;
}

.calendar-view-date-picker .flatpickr-day {
  max-width: 100% !important;
  height: 30px !important;
  line-height: 30px !important;
  border: 1px dotted #dae1e7;
  border-radius: 0 !important;
  flex-basis: auto;
}

.sidebar-box {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.calendar-icon {
  position: absolute;
  top: 1px;
  right: -3px;
  height: 15px;
}

.calendar-icon-listMonth {
  position: absolute;
  left: 175px;
  top: 7px;
  height: 20px;
}

.calendar-icon-listMonth text {
  position: absolute;
  left: 175px;
  top: 7px;
  height: 20px;
  stroke-width: 2px;
  stroke: rgba(105, 172, 223, 1);
}

.calendar-icon-listMonth path {
  fill-opacity: 1;
  stroke-width: 4;
  stroke: rgba(105, 172, 223, 1);
}

.calendar-icon-timeGridDay {
  position: absolute;
  top: 1px;
  right: 0px;
  height: 20px;
}
.calendar-icon-resourceTimeGridDay {
  position: absolute;
  top: 1px;
  right: 0px;
  height: 20px;
}

.calendar-icon-timeGridWeek {
  position: absolute;
  top: 1px;
  right: 0px;
  height: 15px;
}

.calendar-icon-resourceTimeGridWeek,
.calendar-icon-resourceTimeGridThreeDay {
  position: absolute;
  top: 1px;
  right: 0px;
  height: 15px;
}

.calendar-icon-dayGridMonth {
  position: absolute;
  top: 1px;
  right: 0px;
  height: 15px;
}

.disabled .list-group-item .avatar .avatar-tag .bg-light-info {
  background-color: lightgray !important;
}

.calendar-icon-timeGridDay svg {
  position: absolute;
  top: 1px;
  right: 0px;
  height: 20px;
}
.calendar-icon-resourceTimeGridDay svg {
  position: absolute;
  top: 1px;
  right: 0px;
  height: 20px;
}

.calendar-icon-timeGridWeek svg {
  position: absolute;
  top: 1px;
  right: 0px;
  height: 15px;
}

.calendar-icon-resourceTimeGridWeek svg {
  position: absolute;
  top: 1px;
  right: 0px;
  height: 15px;
}

.calendar-icon-dayGridMonth svg {
  position: absolute;
  top: 1px;
  right: 0px;
  height: 15px;
}

.fc-daygrid-day-bg {
  display: none !important;
}

.fc-daygrid-event {
  white-space: break-spaces;
}

a.normal-link {
  color: #6e6b7b;
}

@media (max-width: 767.98px) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: block;
    text-align: center;
  }
  .fc .fc-toolbar .fc-toolbar-title {
    font-size: 16px;
    margin-top: 10px;
  }

  .fc-header-toolbar .fc-toolbar-chunk {
    display: inline-flex;
  }
  .fc .fc-timegrid-slot {
    height: 30px !important;
  }
  .fc-timegrid-event-harness {
    max-width: initial !important;
  }
}

[dir] .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  font-size: 12px;
}

.sticky-column {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #fff !important; /* Adjust as needed */
}

.ui-loader.show .overlay {
  z-index: 10;
  opacity: 0.3;
}

.flatpickr-rContainer {
  width: 100%;
}

.flatpickr-days {
  width: 100%;
  max-width: 100%;
}

[dir] .flatpickr-input[disabled] {
  border-color: #ebe9f1;
  cursor: inherit;
  background-color: hsl(0, 0%, 95%) !important;
}

[dir] .form-control:disabled {
  border-color: #ebe9f1;
  cursor: inherit;
  background-color: hsl(0, 0%, 95%) !important;
}

.emptyList {
  color: rgb(110, 107, 123);
}

.vertical-layout.vertical-menu-modern.menu-collapsed
  .main-menu
  .navbar-header
  .brand-text,
.vertical-layout.vertical-menu-modern.menu-collapsed
  .main-menu
  .modern-nav-toggle {
  display: inherit;
}

[dir="ltr"] .vertical-layout .main-menu .navigation a.active {
  background: linear-gradient(
    118deg,
    #6E847B,
    rgba(79, 179, 163, 0.6)
  ) !important;
}

[dir] .flatpickr-calendar .flatpickr-day.selected,
[dir] .flatpickr-calendar .flatpickr-day.selected:hover {
  border-color: initial;
}

[dir] .chat-app-window .chat-app-form{
  height: initial;
  padding: 1rem !important;
}

.dropdown-menu{
  min-width: initial;
  cursor: pointer;
}

.customTooltip{
  width: 500px;
}

.largeOsrodek {
 margin-left:55px
}
.largeOsrodek img {
 width:100px;
}
.higherBorder .fc-timegrid-slots tr:nth-child(odd) td{
  border-top:1px solid grey !important;
}